import React from "react";

const Progress = ({
  percent,
  showPercent = true,
  percentLocation = "bottom",
}) => {
  return (
    <div className="w-full flex flex-col">
      <div className="relative h-5 w-full bg-white/40 transition-all border shadow-lg rounded-lg overflow-hidden flex items-center">
        <div
          className="h-full rounded-lg bg-[#1A5895BF] transition-all"
          style={{ width: percent.toFixed(2).toString() + "%" }}
        ></div>
        {showPercent && percentLocation === "inline" && (
          <span className="ml-auto px-2">{percent.toFixed(4)}%</span>
        )}
      </div>
      {showPercent && percentLocation === "bottom" && (
        <span className="ml-auto">{percent.toFixed(4)}%</span>
      )}
    </div>
  );
};

export default Progress;
