import React from "react";

const Roadmap = () => {
  return (
    <div>
      <h1 className="text-center text-2xl font-bold">Roadmap</h1>
      <div className="border flex items-center justify-center gap-5 shadow-lg rounded-lg p-4 mt-5 min-h-[320px] lg:min-h-[420px]">
        <span className="text-5xl">COMING SOON</span>
      </div>
    </div>
  );
};

export default Roadmap;
