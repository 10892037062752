const addToMetamask = async (address, symbol, logo) => {
  if (!window || !window.ethereum) {
    return;
  }

  if (!address) {
    return;
  }

  if (!symbol) {
    return;
  }

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: address, // The address that the token is at.
          symbol: symbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: 18, // The number of decimals in the token
          image: logo || "", // A string url of the token logo
        },
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export default addToMetamask;