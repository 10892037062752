import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TokenLogo from "./TokenLogo";
import { useApiContext } from "../context/ApiContext";
import { tokenAddressByIndex, allTokensLength } from "../service/factory";
import { tokenByAddress } from "../service/ordinal";
import shortenAddress from "../utils/shortenAddress";
import styled from "styled-components";

const ethers = require("ethers");

const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: true,
    render: (_, token) => <div className="whitespace-nowrap">{token.id}</div>,
  },
  {
    title: "Token",
    dataIndex: "symbol",
    render: (_, token) => {
      return (
        <div className="flex items-center gap-x-1">
          <TokenLogo logo={token?.logo} />
          <span className="font-semibold md:font-extrabold text-base italic">
            {token.symbol}
          </span>
          <span className="flex items-center justify-center text-sm font-medium bg-accent text-white rounded-2xl px-2 whitespace-nowrap">
            MRC-20
          </span>
        </div>
      );
    },
  },
  {
    title: "Name",
    dataIndex: "name",
    render: (name) => <span>{name}</span>,
  },
  {
    title: "Address",
    dataIndex: "address",
    render: (address) => <span>{shortenAddress(address, 15)}</span>,
  },
  {
    title: "Minted",
    dataIndex: "totalSupply",
    render: (totalSupply) => (
      <span>{ethers.formatEther(totalSupply.toString()).split(".")[0]}</span>
    ),
  },
  {
    title: "Max",
    dataIndex: "maxSupply",
    render: (maxSupply) => (
      <span>{ethers.formatEther(maxSupply?.toString()).split(".")[0]}</span>
    ),
  },
  {
    title: "Limit",
    dataIndex: "limit",
    render: (limit) => (
      <span>{ethers.formatEther(limit?.toString()).split(".")[0]}</span>
    ),
  },
  {
    title: "Mint",
    dataIndex: "mint",
    render: (_, token) => (
      <Link
        id="mint"
        to={`/mint-token?address=${token.address}`}
        className="underline cursor-pointer"
      >
        Mint
      </Link>
    ),
  },
];

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #ffffff;
    color: #000000 !important;
  }
  .anticon {
    color: var(--secondary);
  }
  .ant-table-column-has-sorters:hover {
    background: var(--body) !important;
    color: var(--darkBlue) !important;
  }
  .ant-table-tbody > tr > td {
    background-color: #ffffff;
    color: #000000;
  }
  .ant-table-tbody:hover {
    background: var(--body) !important;
    color: var(--darkBlue) !important;
  }
  .ant-table-cell-row-hover {
    background: var(--body) !important;
    color: var(--darkBlue) !important;
  }
`;

const Assets = () => {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { rpcProvider, network } = useApiContext();

  useEffect(() => {
    if (!rpcProvider) {
      return;
    }

    const getTokenById = async (i) => {
      const address = await tokenAddressByIndex(rpcProvider, network, i);
      const token = await tokenByAddress(rpcProvider, address);

      return {
        id: i + 1,
        address,
        ...token,
      };
    };

    const getTokens = async () => {
      setLoading(true);
      const length = await allTokensLength(rpcProvider, network);

      if (!length || !Number(length.toString())) {
        setLoading(false);
        return;
      }

      const promises = [];

      for (let i = 0; i < Number(length.toString()); i++) {
        promises.push(getTokenById(i));
      }

      const tokens = await Promise.all(promises);

      setTokens(tokens);
      setLoading(false);
    };

    getTokens();
  }, [rpcProvider, network]);

  return (
    <div>
      <h1 className="text-center text-2xl font-bold">ASSETS</h1>
      <div className="border flex gap-5 bg-white shadow-lg rounded-lg mt-5 min-h-[320px] lg:min-h-[420px]">
        <StyledTable
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={tokens}
          loading={loading}
          rowClassName="bg-body text-primary hover:bg-white"
          className="w-full"
          onRow={(record) => {
            return {
              onClick: (e) => {
                if (e.target.id !== "mint") {
                  navigate(`/token/${record.address}`);
                }
              },
            };
          }}
        />
      </div>
    </div>
  );
};

export default Assets;
