import React, { useState, useEffect } from "react";
import { useApiContext, switchNetwork } from "../context/ApiContext";
import { useAdressContext } from "../context/AddressContext";
import { Card, Button, Image } from "antd";
import Progress from "./Progress";
import {
  mintToken,
  maxSupplyByAddress,
  totalSupplyByAddress,
} from "../service/ordinal";
import queueNotification from "../utils/queueNotification";
import SuccessModal from "./SuccessModal";
import SelectAddressFromWallet from "./SelectAddressFromWallet";
import { tokenAddressByIndex } from "../service/factory";
import addToMetamask from "../utils/addToMetamask";
import BigNumber from "bignumber.js";

const ethers = require("ethers");

const TENPOW18 = new BigNumber(10).pow(18);
const ZERO = new BigNumber(0);

const Home = () => {
  const { rpcProvider, network } = useApiContext();
  const { address, provider } = useAdressContext();
  const [loading, setLoading] = useState(false);
  const [mintPercent, setMintPercent] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockHash, setBlockHash] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [totalSupply, setTotalSupply] = useState(ZERO);
  const [maxSupply, setMaxSupply] = useState(ZERO);

  useEffect(() => {
    if (!rpcProvider || !network) {
      return;
    }
    const getToken = async () => {
      setLoading(true);
      const address = await tokenAddressByIndex(rpcProvider, network, 0);

      setTokenAddress(address);
      console.log("tokenAddress", address);

      const totalSupply = await totalSupplyByAddress(rpcProvider, address);
      const maxSupply = await maxSupplyByAddress(rpcProvider, address);

      setLoading(false);

      setTotalSupply(new BigNumber(totalSupply));
      setMaxSupply(new BigNumber(maxSupply));

      const percent = new BigNumber(totalSupply)
        .div(new BigNumber(maxSupply))
        .mul(new BigNumber(100));

      setMintPercent(percent);
    };

    getToken();
  }, [rpcProvider, network]);

  const handleMint = async () => {
    console.log("selectedAddress", address);

    if (!address) {
      return;
    }

    setLoading(true);
    try {
      console.log(
        "Mint Token:",
        provider,
        tokenAddress,
        ethers.parseEther("1000")
      );
      await switchNetwork(network);
      const mintReceipt = await mintToken(
        provider,
        tokenAddress,
        ethers.parseEther("1000"),
        ethers.parseEther("0.001")
      );

      await mintReceipt.wait();
      console.log(`Transaction successful with hash: ${mintReceipt.hash}`);
      setBlockHash(mintReceipt.hash);
      setIsModalOpen(true);
      queueNotification({
        header: "Success",
        message: "Token created successfully.",
        status: "success",
      });
    } catch (error) {
      console.log(`Transaction failed with error: ${error.message}`);
      queueNotification({
        header: "Error!",
        message: error.message,
        status: "error",
      });
    }
    setLoading(false);
  };

  return (
    <>
      <main className="w-full min-h-[320px] lg:min-h-[420px]">
        <div className="mt-8 flex items-start px-7 gap-14 max-md::items-center max-md:flex-col">
          <div className="col-span-12 md:col-span-4 flex items-center gap-x-1 justify-center">
            <Card
              bordered={true}
              className="bg-body text-black text-sm w-80 p-3 shadow-xl flex flex-col items-center h-full"
            >
              <h2 className="text-3xl font-bold text-center mb-3">
                $YIELD
              </h2>
              <pre>
                {JSON.stringify(
                  {
                    p: "BLC-20",
                    op: "deploy",
                    tick: "YIELD",
                    max: "21000000000",
                    lim: "1000",
                  },
                  null,
                  4
                )}
              </pre>
            </Card>
          </div>
          <div className="w-full text-black text-md">
            <p className="my-4">
              Blobscriptions are on-chain digital artifacts within the Blast
              ecosystem. They’re created by using Blast calldata to store
              information directly on the blockchain. These Blobscriptions can
              then be traded, bought, sold or stored by Blast users.
            </p>
            <h2 className="text-2xl font-semibold mb-5">
              It’s all in the remarks!
            </h2>
            <p className="my-2">
              Total Minted:{" "}
              {totalSupply.div(TENPOW18).toNumber().toLocaleString()} /{" "}
              {maxSupply.div(TENPOW18).toNumber().toLocaleString()}
            </p>
            <Progress percent={mintPercent} />
            <div className="flex gap-2">
              {/* <Link as={Button} to='/create-token' className="px-10 h-10 border shadow-lg rounded-md ml-5-xl font-medium text-black mt-5 py-2">
                Create Token
              </Link> */}
              <div className="flex gap-2 relative items-center justify-center">
                {address ? (
                  <>
                    <Button
                      loading={loading}
                      disabled={loading}
                      onClick={handleMint}
                      className="px-10 h-10 mx-auto border shadow-lg rounded-md ml-5-xl font-semibold disabled:text-secondary text-black mt-5 py-2"
                    >
                      Mint Token
                    </Button>
                    <Button
                      loading={loading}
                      disabled={loading}
                      onClick={() =>
                        addToMetamask(
                          tokenAddress,
                          "YIELD",
                          "https://blast.blobscriptions.xyz/logo.png"
                        )
                      }
                      className="px-10 h-10 mx-auto border shadow-lg rounded-md ml-5-xl font-semibold text-black mt-5 py-2 disabled:text-secondary"
                    >
                      Add to Metamask
                    </Button>
                  </>
                ) : (
                  <SelectAddressFromWallet />
                )}
              </div>
            </div>
            <div className="w-full grid grid-cols-2 my-5 gap-5">
              <div className="flex items-center gap-1">
                <Image src="/assets/created-at.svg" width={12} height={12} />
                <a
                  href={`https://testnet.blastscan.io/block/621925`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Created at : #{621925}
                </a>
              </div>
              <div>Max Supply : {maxSupply.div(TENPOW18).toNumber().toLocaleString()}</div>
              <div className="flex items-center gap-1">
                <Image src="/assets/created-on.svg" width={12} height={12} />
                Minting Fee: 0.001 ETH
              </div>
              <div>Limit : 1000/mint</div>
            </div>
          </div>
        </div>
        <SuccessModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          blockHash={blockHash}
          title="Token minted successfully!"
        />
      </main>
    </>
  );
};

export default Home;
