import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "..//NotFound";
import Roadmap from "../Roadmap";
import Disclaimer from "../Disclaimer";
import Home from "../Home";
import Assets from "../Assets";
import Token from "../Token";
import MintToken from "../MintToken";
import CreateToken from "../CreateToken";

const CustomContent = () => {
  return (
    <div className="p-6 flex-1 max-w-6xl w-full mx-auto">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/token/:address" element={<Token />} />
        <Route path="/create-token" element={<CreateToken />} />
        <Route path="/mint-token" element={<MintToken />} />
        <Route path="/marketplace" element={<Roadmap />} />
        <Route path="/assets" element={<Assets />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default CustomContent;
