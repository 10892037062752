// src/App.js

import React from "react";

import "./App.css";
import { Layout } from "antd";
import CustomContent from "./components/AppLayout/Content";
import Header from "./components/AppLayout/Header";
import { ConfigProvider, theme } from "antd";
const { darkAlgorithm } = theme;
const { Content } = Layout;

const App = () => {
  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
        token: {
          colorPrimary: "#FF87EE",
        },
      }}
    >
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Layout>
          <Header />
          <Content className="overflow-y-auto h-[calc(100vh-65px)] bg-body">
            <div className="flex flex-col h-full">
              <CustomContent />
            </div>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default App;
