import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import SelectAddressFromWallet from "../SelectAddressFromWallet";

const { Header: AntdHeader } = Layout;

const navItems = [
  {
    title: "Home",
    route: "/",
  },
  {
    title: "Leaderboard",
    external: true,
    route:
      "https://testnet.blastscan.io/token/0x942598A9dF483a076fAc0951a55eb5B3AF60B56B/balances",
  },
  {
    title: "Roadmap",
    route: "/roadmap",
  },
  {
    title: "Marketplace",
    route: "/marketplace",
  },
  {
    title: "Assets",
    route: "/assets",
  },
];

const Header = () => {
  // const { network, setNetwork, setRPCEndpoint, rpcEndpoint } = useApiContext();

  return (
    <AntdHeader className="bg-body sticky w-full flex items-center justify-between py-2 px-5 text-primary">
      <Link to="/">
        <img alt="logo" src="/assets/logo.svg" width={232} height={60} />
      </Link>
      <nav className="nav flex items-center gap-5">
        <ul className="nav-list flex gap-5">
          {navItems.map((item, index) =>
            item.external ? (
              <a href={item.route} key={index} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            ) : (
              <li className="nav-list-item" key={index}>
                <Link to={item.route}>{item.title}</Link>
              </li>
            )
          )}
          <li className="nav-list-item">
            <a
              href="https://docs.blast.io/tools/faucets"
              rel="noreferrer"
              target="_blank"
            >
              Faucet
            </a>
          </li>
        </ul>

        <SelectAddressFromWallet />
      </nav>
    </AntdHeader>
  );
};

export default Header;
